<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <v-card-title>
        <v-img max-height="40" max-width="40" src="/logo.png" /><span class="pa-4">Welcome to UNDSS Security Reporting System</span>
      </v-card-title>
      <v-card-text class="overflow-y-auto" :style="viewHeight()">
        For managing Daily Security Incitents Reports (DSIR), Staff Lists, and TRIP Log. 
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "BulkImport",
};
</script>
